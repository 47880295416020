import React, { memo, useEffect, useState } from 'react';
import { useGlobalAudioPlayer } from 'react-use-audio-player';
import { HStack, Stack } from '@chakra-ui/react';
import { useBoundStore } from 'app/store';
import { astonradioSelector } from 'app/selectors/astonradioSelector';
import AudioProgress from 'shared/AudioProgress';
import PlayPauseButton from 'shared/PlayPauseButton';
import VolumeControl from 'shared/VolumeControl';
import PlaylistControl from 'shared/PlaylistControl';
import Api from 'shared/backApi';
import { ym } from 'shared/lib/ym';

const AstonRadio = ({ ...props }) => {
  const { tracks, activeTrackId, setActiveTrack, initTracks } = useBoundStore(astonradioSelector);
  const { load, togglePlayPause, setVolume, playing, volume } = useGlobalAudioPlayer();
  const [wasPlayed, setWasPlayed] = useState(false);

  useEffect(() => {
    async function fetchRadioData() {
      try {
        const tracks = await Api.getRadioData();
        await initTracks(tracks);
      } catch (e) {
        return e;
      }
    }
    fetchRadioData();
  }, []);
  useEffect(() => {
    tracks?.[activeTrackId] &&
      load(tracks[activeTrackId]?.media_url, {
        autoplay: wasPlayed,
        html5: true,
        format: 'mp3',
        initialVolume: wasPlayed ? volume : 0.1,
        onend: () => tracks[activeTrackId - 1] && setActiveTrack(activeTrackId - 1),
      });
  }, [activeTrackId, load, tracks]);

  const handlePlayPause = () => {
    if (!wasPlayed) {
      setWasPlayed(true);
    }
    ym('radio_play_button_click');
    togglePlayPause();
  };

  return (
    <Stack direction="row" spacing="26px" align="center" whiteSpace="nowrap" {...props}>
      <PlayPauseButton isPlaying={playing} onPlayPauseClick={handlePlayPause} />
      <AudioProgress activeTrack={tracks[activeTrackId]} />
      <HStack spacing="16px" align="center">
        <VolumeControl onVolumeChange={setVolume} volume={volume} />
        <PlaylistControl />
      </HStack>
    </Stack>
  );
};

export default memo(AstonRadio);
