import tg_green from 'assets/People/Icons/tg_green.svg';
import inst_green from 'assets/People/Icons/inst_green.svg';
import youtube_green from 'assets/People/Icons/youtube_green.svg';
import vk_green from 'assets/People/Icons/vk_green.svg';

export const socialMedias = [
  {
    href: 'https://t.me/aston_radio',
    src: tg_green,
    alt: 'tg',
  },
  {
    href: 'https://www.instagram.com/aston.it/',
    src: inst_green,
    alt: 'inst',
    isBlocked: true,
  },
  {
    href: 'https://www.youtube.com/@astonlive2023',
    src: youtube_green,
    alt: 'youtube',
  },
  {
    href: 'https://vk.com/aston.company',
    src: vk_green,
    alt: 'vk',
  },
];

export const offices = [
  {
    city: 'г. Москва',
    address: `Пресненская наб., 6, стр. 2 <br/>
    БЦ «Башня Империя», 50 эт., оф. 13 <br/>
    Россия`,
  },
  {
    city: 'г. Санкт-Петербург',
    address: `Владимирский пр-т, 23 <br />
    БЦ «Ренессанс Холл», 8 эт., оф. 801 <br/>
    Россия`,
  },
  {
    city: 'г. Казань',
    address: `ул. Н. Ершова, 76/1, офис 213 <br/>
    Россия`,
  },
  {
    city: 'г. Ростов-на-Дону',
    address: `ул. Текучева, 246, 3 эт. <br/>
    Россия`,
  },
  {
    city: 'г. Н.Новгород',
    address: `ул. Белинского, 63, <br/> 
    БЦ «Этажи» <br/>
    Россия`,
  },
  {
    city: 'г. Самара',
    address: `Московское ш. 4, IT-парк Монте Роза <br/>
    Россия`,
  },
  {
    city: 'г. Алматы',
    address: `мкр-н Коктем-2, зд. 22 <br/>
    Бостандыкский р-н <br/>
    Казахстан`,
  },
  {
    city: 'г. Минск',
    address: `пр-т Победителей, 7А, <br/>
    БЦ «Royal Plaza», 24 эт. <br/>
    Беларусь`,
  },
  {
    city: 'г. Витебск',
    address: `Московский пр-т, 70–1 <br/>
    ул. Строителей, 11А <br/>
    Беларусь`,
  },
  {
    city: 'г. Полоцк',
    address: `ул. Октябрьская, 25 <br/>
    Беларусь`,
  },

  {
    city: 'г. Гомель',
    address: `ул. Советская, 41Б <br/>
    Беларусь`,
  },
];
