import { createAstonRadioSlice } from 'app/slices/astonradio.slice';
import { createHeaderSlice } from 'app/slices/header.slice';
import { createVacanciesSlice } from 'app/slices/vacancies.slice';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
export const _useBoundStore = create(
  immer((...a) => ({
    ...createHeaderSlice(...a),
    ...createVacanciesSlice(...a),
    ...createAstonRadioSlice(...a),
  })),
);
export function useBoundStore(selector) {
  if (!selector) {
    selector = () => undefined;
  }
  return _useBoundStore(useShallow(selector));
}
