import { useDisclosure, Text, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import React from 'react';
import NavItem from 'shared/NavItem';
import ThemedImage from 'shared/ThemedImage';
import downAngle from 'assets/Icons/DownAngle.svg';

export default function MenuNavitem({ text, links = [] }) {
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <Menu
      placement="bottom-start"
      flip={false}
      preventOverflow={false}
      isOpen={isOpen}
      onClose={onClose}
      isLazy
      boundary="scrollParent"
    >
      <MenuButton
        gap="8px"
        onClick={onToggle}
        sx={{
          span: {
            display: 'flex',
            gap: '8px',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
      >
        <Text textStyle="button.secondary">{text}</Text>
        <ThemedImage
          src={downAngle}
          transform={isOpen ? 'rotate(-180deg)' : 'rotate(0deg)'}
          transition="transform .2s"
        />
      </MenuButton>
      <MenuList
        pos="absolute"
        top={{ laptop: '38px', tablet: '26px' }}
        sx={{ filter: 'drop-shadow(0 1px 20px rgba(3, 18, 35, 0.06))' }}
        _focusVisible={{ outline: 'none' }}
        minW={{ laptop: '247px', tablet: '200px' }}
        display="flex"
        flexDirection="column"
        gap={{ laptop: '16px', tablet: '20px' }}
        p={{ laptop: '24px', tablet: '16px' }}
        bgColor="bg.white"
        color="text.black"
        borderRadius="16px"
        border="1px solid #EAEFE8"
      >
        {links.map((link, i) => (
          <MenuItem key={i} _focus={{}} _hover={{}}>
            <NavItem
              {...link}
              sxText={{
                fontSize: { desktop: '20px', laptop: '18px', tablet: '14px' },
                textAlign: 'left',
              }}
            />
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
