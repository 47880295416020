const breakpoints = {
  mobile: '0px',
  sm: '544px',
  tablet: '656px',
  md: '742px',
  mg: '885px',
  lg: '960px',
  laptop: '992px',
  xl: '1024px',
  xxl: '1200px',
  xxxl: '1350px',
  desktop: '1441px',
};

export default breakpoints;
