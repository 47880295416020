import React from 'react';
import {
  VStack,
  Button,
  Text,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

import { useBoundStore } from 'app/store';
import { headerSelector } from 'app/selectors/headerSelector';
import { links, variants } from 'constants/header';
import { navItem } from 'constants/header/variantNavItem';
import NavItemMobile from 'shared/NavItemMobile';
import { goToForm } from 'shared/lib/goToForm';

import downAngle from 'assets/Icons/DownAngle.svg';

const MobileNavigation = props => {
  const { toggleMenuOpen } = useBoundStore(headerSelector);
  const handleButton = () => {
    goToForm();
    toggleMenuOpen();
  };

  return (
    <VStack
      as={motion.nav}
      variants={variants}
      userSelect="none"
      h={{ mobile: 'calc(100vh - 44px)', tablet: 'calc(100vh - 74px)' }}
      p="32px 22px 32px 20px"
      pos="fixed"
      right={0}
      top={{ mobile: '43.7px', tablet: '73.7px' }}
      gap="24px"
      justifyContent="space-between"
      overflowX="scroll"
      zIndex={1050}
      bgColor="bg.white"
      {...props}
    >
      <VStack gap="24px">
        {links?.map((el, i) =>
          el.links ? (
            <Accordion as={motion.div} variants={navItem} key={i} minW="170px" allowToggle>
              <AccordionItem border="none">
                {({ isExpanded }) => (
                  <>
                    <AccordionButton
                      w="full"
                      flex={1}
                      justifyContent="space-between"
                      gap="12px"
                      p="0"
                      _hover={{}}
                    >
                      <Text align="start" textStyle="button.secondary" lineHeight="40px">
                        {el.text}
                      </Text>
                      <Image
                        src={downAngle}
                        transform={isExpanded ? 'rotate(-180deg)' : 'rotate(0deg)'}
                        transition="transform .2s"
                      />
                    </AccordionButton>
                    <AccordionPanel
                      display="flex"
                      flexDirection="column"
                      motionProps={{ unmountOnExit: true }}
                      p="0"
                    >
                      {el.links.map((link, index) => (
                        <NavItemMobile
                          key={index}
                          href={link.href}
                          text={link.text}
                          isBlank={link.isBlank}
                          toggle={toggleMenuOpen}
                          sxText={{ fontSize: '12px', lineHeight: '44px' }}
                        />
                      ))}
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
          ) : (
            <NavItemMobile
              key={i}
              toggle={toggleMenuOpen}
              {...el}
              sxText={{ lineHeight: '44px' }}
            />
          ),
        )}
      </VStack>

      <Button as={motion.button} variants={navItem} size="secondary" onClick={handleButton}>
        Оставить заявку
      </Button>
    </VStack>
  );
};

export default MobileNavigation;
