import React from 'react';
import { Box, Button, Link, VStack } from '@chakra-ui/react';
import { Popover, PopoverTrigger, PopoverContent } from '@chakra-ui/popover';
import Playlist from 'assets/Icons/Playlist.svg';
import { useBoundStore } from 'app/store';
import { astonradioSelector } from 'app/selectors/astonradioSelector';
import ThemedImage from 'shared/ThemedImage';

const PlaylistControl = () => {
  const { tracks, setActiveTrack, activeTrackId } = useBoundStore(astonradioSelector);

  return (
    <Popover gutter={5}>
      <PopoverTrigger>
        <Button variant="default" boxSize="24px" flexShrink={0} _hover={{ color: 'none' }}>
          <ThemedImage src={Playlist} boxSize="24px" />
        </Button>
      </PopoverTrigger>
      <PopoverContent bgColor="bg.grayLight" borderRadius="16px" w="fit-content" p="24px">
        <VStack spacing="8px" mb="31px" w="121px">
          {tracks?.map(({ publication_date }, i) => (
            <Box
              key={i}
              textStyle="radio"
              textAlign="center"
              w="full"
              borderRadius="40px"
              bgColor={activeTrackId === i && 'bg.accent'}
              border="1px solid"
              borderColor="bg.gray"
              p="5px"
              onClick={() => activeTrackId === i || setActiveTrack(i)}
              cursor="pointer"
              _hover={
                activeTrackId !== i && {
                  bgColor: 'bg.gray',
                }
              }
            >
              Выпуск {publication_date}
            </Box>
          ))}
        </VStack>
        <Link target="_blank" href="https://t.me/aston_radio">
          <Button p="12px 0px" fontSize="14px" w="full">
            Все выпуски
          </Button>
        </Link>
      </PopoverContent>
    </Popover>
  );
};

export default PlaylistControl;
