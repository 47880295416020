import React, { memo } from 'react';
import { Image } from '@chakra-ui/react';
import commonAssets from 'assets/common';
import { useBoundStore } from 'app/store';
import { headerSelector } from 'app/selectors/headerSelector';

const Logo = props => {
  const { isLight } = useBoundStore(headerSelector);

  return (
    <Image
      src={isLight ? commonAssets.logo.Aston.light : commonAssets.logo.Aston.dark}
      alt="Logo"
      {...props}
    />
  );
};

export default memo(Logo);
