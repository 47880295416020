import React from 'react';
import { Img } from '@chakra-ui/react';
import { useBoundStore } from 'app/store';
import { headerSelector } from 'app/selectors/headerSelector';

const ThemedImage = ({ src, ...props }) => {
  const { isLight } = useBoundStore(headerSelector);
  return (
    <Img
      src={src}
      filter={isLight ? `brightness(0) invert(1)` : 'none'}
      transition="filter .2s linear"
      {...props}
    />
  );
};
export default ThemedImage;
