import React, { memo } from 'react';
import { Center } from '@chakra-ui/react';
import Play from 'assets/Icons/PlayButton.svg';
import Pause from 'assets/Icons/PauseButton.svg';
import { useBoundStore } from 'app/store';
import { headerSelector } from 'app/selectors/headerSelector';
import ThemedImage from 'shared/ThemedImage';

const PlayPauseButton = ({ isPlaying, onPlayPauseClick, ...props }) => {
  const { isLight } = useBoundStore(headerSelector);

  return (
    <Center
      cursor="pointer"
      border="1.6px solid"
      borderColor={`bg.${isLight ? 'white' : 'black'}`}
      borderRadius="full"
      boxSize="60px"
      onClick={onPlayPauseClick}
      flexShrink={0}
      {...props}
    >
      <ThemedImage src={isPlaying ? Pause : Play} boxSize="24px" />
    </Center>
  );
};

export default memo(PlayPauseButton);
