import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';
import { VStack } from '@chakra-ui/react';
import AnimatedCircles from 'shared/AnimatedCircles';
import Footer from 'widgets/Footer';
import Header from 'widgets/Header';
import Cookies from 'features/Cookies';
import { overlapOffset } from 'constants/pageLayout';
import { useBoundStore } from 'app/store';
import { throttle } from 'shared/lib/throttle';
import { headerSelector } from 'app/selectors/headerSelector';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Head from 'shared/Head';
import data from './data.json';
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(ScrollTrigger, useGSAP);

ScrollTrigger.config({
  ignoreMobileResize: true,
});

function PageLayout({ children }) {
  const { setIsLight } = useBoundStore(headerSelector);
  const headerRef = useRef(null);
  const componentRef = useRef(null);

  const checkHeaderAndFooterOverlaped = () => {
    if (headerRef.current && componentRef.current) {
      const a = headerRef.current.getBoundingClientRect();
      const b = componentRef.current.getBoundingClientRect();
      if (a.top + overlapOffset <= b.top + b.height && a.top + a.height > b.top + overlapOffset) {
        setIsLight(true);
      } else {
        setIsLight(false);
      }
    }
  };

  useEffect(() => {
    const throttledCheckFn = throttle(checkHeaderAndFooterOverlaped);
    window.addEventListener('scroll', throttledCheckFn);
    throttledCheckFn();
    return () => window.removeEventListener('scroll', throttledCheckFn);
  }, []);

  return (
    <>
      <Head {...data.head} />
      <VStack
        position="relative"
        zIndex={1}
        w="100%"
        boxSizing="border-box"
        minH="100vh"
        overflowX="hidden"
        gap={0}
      >
        <AnimatedCircles
          top={{ tablet: '350px', mobile: '400px' }}
          left={{ laptop: '35%', tablet: '25%', mobile: '35%' }}
          boxSize={{ laptop: '200px', tablet: '50px' }}
        />
        <Header ref={headerRef} />
        {children}
        <Footer ref={componentRef} />
        <Cookies />
      </VStack>
    </>
  );
}

export default PageLayout;
