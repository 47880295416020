import { keyframes } from '@chakra-ui/react';

export const spinAnimation = keyframes`
from{
	transform: rotate(0deg);
}
to{
	transform: rotate(360deg);
}
`;
