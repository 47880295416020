export const createAstonRadioSlice = set => ({
  tracks: [],
  activeTrackId: 0,
  initTracks(data) {
    set(state => {
      state.tracks = data;
    });
  },
  setActiveTrack(id) {
    set(state => {
      state.activeTrackId = id;
    });
  },
});
