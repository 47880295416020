import React, { memo } from 'react';
import { Button, HStack } from '@chakra-ui/react';

import { useBoundStore } from 'app/store';
import { headerSelector } from 'app/selectors/headerSelector';
import { links } from 'constants/header';
import NavItem from 'shared/NavItem';
import { goToForm } from 'shared/lib/goToForm';
import MenuNavitem from 'shared/MenuNavitem/MenuNavitem';

const Navigation = () => {
  const { isLight } = useBoundStore(headerSelector);

  return (
    <HStack
      as="nav"
      gap="40px"
      transition="color .2s linear"
      hideBelow="md"
      color={`text.${!isLight ? 'black' : 'white'}`}
      userSelect="none"
      whiteSpace="nowrap"
    >
      {links?.map((data, i) =>
        data.links ? (
          <MenuNavitem key={i} {...data} />
        ) : (
          <NavItem key={i} {...data} sxText={{ textAlign: 'center' }} />
        ),
      )}
      <Button size="secondary" onClick={goToForm} variant={isLight && 'light'}>
        Оставить заявку
      </Button>
    </HStack>
  );
};

export default memo(Navigation);
